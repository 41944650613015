import React, { useState, useEffect, FC } from "react";
import Logo from "../assets/images/logo_hd.svg";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  onPressNoBtn: Function;
  onPressYesBtn: Function;
}

const OrderCancelModal: FC<Props> = ({ onPressNoBtn, onPressYesBtn }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: {isFrontPage: {eq: true}}) {
        edges {
          node {
            homePage {
              ageVerificationModal {
                logo {
                  node {
                    sourceUrl
                  }
                }
                modalBody
                termsAndConditions
              }
            }
          }
        }
      }
    }
  `)
  const { ageVerificationModal } = data.allWpPage.edges[0].node.homePage;
  return (
    <div className="age_verifi_modal fixed inset-0 bg-white bg-opacity-20 flex items-center justify-center z-50">
      <div className="bg-white rounded-[21px] shadow-2xl shadow-[#e8e5e5]  border-[0.5px] border-[#f1f1f1] p-4 mx-[30px] w-full  lg:w-1/2">
        <div className="text-center">
          <img
            src={
              ageVerificationModal?.logo?.node?.sourceUrl
                ? ageVerificationModal?.logo?.node?.sourceUrl
                : Logo
            }
            alt="Logo"
            width={200}
            height={80}
            className="mx-auto mb-4"
          />
          <div className="age_verifi_modal_content">
            <h4 className=" mb-4 font-sofiaRegular font-regular text-[20px] ">
              ARE YOU SURE YOU WANT TO CANCEL THIS ORDER ?
            </h4>
          </div>

          <div
            className="flex justify-center md:space-x-4 my-4 md:flex-row 
					flex-col"
          >
            <button
              onClick={() => {
                onPressYesBtn(false);
              }}
              className="modal-btn py-2 px-2 mb-5 md:mb-0 focus:outline-none hover:bg-gray-800"
            >
              YES
            </button>
            <button
              onClick={() => {
                onPressNoBtn(false);
              }}
              className="modal-btn py-2 px-4 focus:outline-none hover:bg-gray-800"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCancelModal;
