import React, { useState, FC, useEffect } from "react";
import {
  getOrderList,
  updateOrderStatus,
} from "../../services/woocommerceServices/Orders";
import axios from "axios";
import PageLoader from "../common/Loader/PageLoader";
import moment from "moment";
import OrderCancelModal from "../OrderCancelModal";
import ViewOrderDetails from "./viewOrderDetails";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { ArrowLeft, ArrowRight } from "../common/Svg";
import Pagination from "../common/Pagination/Pagination";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { rootReducer } from "../../store";
import { graphql, useStaticQuery } from "gatsby";
import { verifyCoupon } from "../../services/woocommerceServices/Checkout";
import { Coupon } from "../../types";

interface ModalContent {
  modalBody: string;
  termsAndConditions: string;
}

interface props {
  modalContent: ModalContent;
}

const OrdersComponent = () => {
  const { isLoggedIn, userId, email } = useJWTChecker();
  const [orderList, setOrderList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowOrderDetailsModal, setIsShowOrderDetailsModal] =
    useState<boolean>(false);
  const [orderItemInfo, setOrderItemInfo] = useState<any>([]);
  const [cancelOrderInfo, setCancelOrderInfo] = useState<any>([]);
  const [couponsList, setCouponsList] = useState<any>([]);
  const [appliedCouponPrice, setAppliedCouponPrice] = useState(0);
  const { refreshUI } = useSelector((state: rootReducer) => state.cart);

  const data = useStaticQuery(graphql`
    query {
      wpPage(isFrontPage: { eq: true }) {
        homePage {
          couponCode
        }
      }
    }
  `);

  const coupons: Partial<Coupon[]> = useSelector(
    (state: rootReducer) => state.cart.coupon
  );

  useEffect(() => {
    verifyCoupon(data.wpPage.homePage.couponCode, coupons, userId, email).then(
      (verified_coupon: Coupon[]) => {
        setCouponsList(verified_coupon);
        if (verified_coupon.length > 0) {
          const totalAmount = verified_coupon?.reduce(
            (total: any, item: any) => total + parseFloat(item.amount),
            0
          );
          setAppliedCouponPrice(totalAmount);
        }
      }
    );
  }, [data.wpPage.homePage.couponCode]);

  useEffect(() => {
    setIsOpen(false);
    setIsShowOrderDetailsModal(false);
  }, [refreshUI]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchOrderList();
    }
  }, [isLoggedIn, userId, email, currentPage]);

  const fetchOrderList = async () => {
    setLoader(true);
    try {
      const getListOfOrders: any = await getOrderList(
        currentPage,
        "date",
        Number(userId)
      );

      if (getListOfOrders && getListOfOrders?.data?.length > 0) {
        setOrderList(getListOfOrders?.data);
        setPage(getListOfOrders.page);
      }
      setLoader(false);
    } catch (error) {
      setOrderList([]);
      setLoader(false);
    }
  };

  const confirmCancelOrder = async () => {
    setIsOpen(false);
    if (cancelOrderInfo) {
      const payload = {
        status: "cancelled",
      };

      try {
        const getUpdatedOrderStatusResponse = await updateOrderStatus(
          cancelOrderInfo.id,
          payload
        );
        if (getUpdatedOrderStatusResponse) {
          toast.success("Order cancelled");
          fetchOrderList();
        }
        setIsShowOrderDetailsModal(false);
      } catch (error) {
        toast.error("Something went wrong, please try again later.");
        setIsShowOrderDetailsModal(false);
      }
    }
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          <div>
            <div className="overflow-x-auto">
              <div className="min-w-full bg-white shadow-md rounded-lg overflow-x-auto">
              {!isShowOrderDetailsModal && (
                <div className="table-responsive">
                  <table className="min-w-full leading-normal border my_account_order_table">
                   
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-5 py-5 border-b  bg-bg-grey text-left text-[15px] font-sofiaSemibold font-semiBold text-black  "
                          >
                            Order ID
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-5 border-b  bg-bg-grey text-left text-[15px] font-sofiaSemibold font-semiBold text-black "
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-5 border-b  bg-bg-grey text-left text-[15px] font-sofiaSemibold font-semiBold text-black "
                          >
                            Order Date
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-5 border-b bg-bg-grey text-left text-[15px] font-sofiaSemibold font-semiBold text-black "
                          >
                            Total Amount
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-5 border-b  bg-bg-grey text-center text-[15px] font-sofiaSemibold font-semiBold text-black "
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                    
                    <tbody>
                      {orderList &&
                      orderList?.length > 0 &&
                      !isShowOrderDetailsModal ? (
                        orderList?.map((item: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white  text-sm">
                                <p className="text-gray-900 whitespace-no-wrap font-sofiaRegular font-regular">
                                  #{item?.id || "-"}
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap font-sofiaRegular font-regular capitalize">
                                  {item?.status || "-"}
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap font-sofiaRegular font-regular">
                                  {item?.date_created
                                    ? moment(item?.date_created).format(
                                        "D MMMM, YYYY"
                                      )
                                    : "-"}
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap text-center font-sofiaRegular font-regular">
                                  {item?.currency_symbol}
                                  { item.coupon_lines.length !== 0 ? (Number(item?.total) +  Number(item?.total_tax) - Number(appliedCouponPrice)).toFixed(2): (Number(item?.total) +  Number(item?.total_tax)).toFixed(2)}

                                  {/* {(
                                    Number(item?.total) +
                                    Number(item?.total_tax) -
                                    Number(appliedCouponPrice)
                                  ).toFixed(2)} */}
                                </p>
                              </td>
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <div className="flex justify-center space-x-2">
                                  <button
                                    onClick={() => {
                                      setOrderItemInfo(item);
                                      setIsShowOrderDetailsModal(true);
                                    }}
                                    className="btn mx-1 !w-[120px] lg:py-2 py-1 px-4 font-sofiaRegular font-regular focus:outline-none text-white bg-black hover:bg-primary rounded-full h-[35px]"
                                  >
                                    View
                                  </button>
                                  {![
                                    "completed",
                                    "cancelled",
                                    "failed",
                                    "refunded",
                                  ].includes(item?.status) ? (
                                    <button
                                      onClick={() => {
                                        // console.log("first",item)
                                        setCancelOrderInfo(item);
                                        setIsOpen(true);
                                      }}
                                      className="btn mx-1  px-4 focus:outline-none bg-bg-grey hover:bg-black hover:text-white font-sofiaRegular font-regular whitespace-nowrap rounded-full !w-[120px] lg:py-2 py-1"
                                    >
                                      Cancel order
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>
                          {!isShowOrderDetailsModal && (
                            <tr>
                              <td colSpan={5} className="text-center">
                                No orders found
                              </td>
                            </tr>
                          )}
                        </>
                      )}

                      {/* <!-- Repeat rows as necessary --> */}
                    </tbody>
                  </table>
                </div>
                )}
              </div>
            </div>
          </div>

          {/*------------------View Order Component -------------------*/}
          {isShowOrderDetailsModal && (
            <ViewOrderDetails
              coupons={couponsList}
              appliedCouponPrice={appliedCouponPrice}
              orderDetails={orderItemInfo}
              onPressBack={() => {
                setIsShowOrderDetailsModal(false);
              }}
              onPressCancelOrder={(data: any) => {
                setCancelOrderInfo(data);
                setIsOpen(true);
              }}
            />
          )}

          {/*------------------Order Cancel Modal -------------------*/}
          {isOpen && (
            <OrderCancelModal
              onPressNoBtn={() => {
                setIsOpen(false);
              }}
              onPressYesBtn={() => {
                confirmCancelOrder();
              }}
            />
          )}
        </>
      )}

      {orderList?.length > 0 && !isShowOrderDetailsModal && page > 1 && (
        <div className="pb-4">
          <Pagination
            page={page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default OrdersComponent;
