import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { getOrderNotes } from "../../services/woocommerceServices/Orders";

interface Props {
  coupons?: any;
  appliedCouponPrice?: any;
  orderDetails: any;
  onPressBack: Function;
  onPressCancelOrder: Function;
}


const ViewOrderDetails: FC<Props> = ({
  coupons,
  appliedCouponPrice,
  orderDetails,
  onPressBack,
  onPressCancelOrder,
}) => {
  // console.log(orderDetails.coupon_lines, "<<<<+======");
  // console.log(orderDetails, "<<<<+======");
  const [notes, SetNotes] = useState('');
  useEffect(() => {
     getOrderNotes(orderDetails.id).then((data:any) => {
      // console.log(data.data, 'test');

      // Step 1: Find the first note with "Tracking number:"
      const trackingNote = data.data.find((note:any) => /Tracking number:/.test(note.note));

      if (trackingNote) {
        // Step 2: Extract the tracking number using regex
        const regex = /Tracking number:\s*(\S+)/;
        const match = trackingNote.note.match(regex);
        
        if (match) {
          // console.log("Tracking number found:", match[1]);
          SetNotes(match[1])
        } else {
          // console.log("Tracking number format not found.");
          SetNotes("N/A")
          
        }
      } else {
        console.log("No note with 'Tracking number' found.");
      }
    
     }).catch((error) => {
      console.log(error, 'error');
     });
    
  }, []);
  return (
    <>
      <div className="max-w-full bg-white border rounded-[12px] overflow-hidden">
        <div className="mb-2 py-3 px-3 flex justify-between items-center bg-bg-grey border-b">
          <h2 className="text-[20px] font-sofiaSemibold font-semiBold text-black  text-center">
            Order details 
          </h2>
          {/* <button
            onClick={() => {
              onPressBack();
            }}
            className="flex items-center w-8 h-8 text-gray-500 hover:text-gray-700 text-xl"
          >
            <CloseIcon />
          </button> */}
        </div>

        <div className="px-3 py-2">
          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Order Id:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700">
                #{orderDetails?.id || "--"}
              </span>
            </label>
          </div>

          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Order Date:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700">
                {orderDetails?.date_created
                  ? moment(orderDetails?.date_created).format("D MMMM, YYYY")
                  : "-"}
              </span>
            </label>
          </div>

          {orderDetails?.line_items?.map((item: any, index: any) => {
            return (
              <div className="cursor-pointer mb-2" key={index}>
                <label
                  htmlFor={"1"}
                  className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
                >
                  <div>
                    <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                      {item?.name || "--"}
                    </span>

                    <div className="flex md:gap-2 flex-col md:flex-row">
                      <h3
                        key={index}
                        className=" capitalize font-sofiaRegular font-regular text-[#9F9F9F] text-[13px]"
                      >
                        Quantity :<span> {item?.quantity}</span>
                      </h3>
                      {item?.meta_data &&
                        item?.meta_data?.length > 0 &&
                        item?.meta_data?.map((option: any, index2: number) => {
                          return (
                            <>
                              {typeof option?.value == "string" && (
                                <h3
                                  key={index2}
                                  className=" capitalize font-sofiaRegular font-regular text-[#9F9F9F] text-[13px]"
                                >
                                  {typeof option?.value == "string"
                                    ? option?.display_key
                                    : ""}
                                  :{" "}
                                  <span>
                                    {typeof option?.value == "string"
                                      ? option?.value
                                      : ""}
                                  </span>
                                </h3>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <span className=" text-[15px] font-sofiaRegular font-regular text-gray-700">
                    {orderDetails?.currency_symbol || "--"}
                    {item?.total || "--"}
                  </span>
                </label>
              </div>
            );
          })}

          <div className="cursor-pointer border-t-2 pt-3 mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Subtotal:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700">
                $ {orderDetails?.total}
              </span>
            </label>
          </div>
          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Shipping Cost:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700">
                {orderDetails?.currency_symbol || "--"}{" "}
                {orderDetails?.shipping_total || "--"}
              </span>
            </label>
          </div>
          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Shipping details:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-right text-gray-700">
                {orderDetails?.shipping?.address_1 ||
                  orderDetails?.billing?.address_1}
                ,
                {orderDetails?.shipping?.address_2 ||
                  orderDetails?.billing?.address_2}
                <h5>
                  {orderDetails?.shipping?.city || orderDetails?.billing?.city},
                  {orderDetails?.shipping?.country ||
                    orderDetails?.billing?.country}
                  ,
                  {orderDetails?.shipping?.state ||
                    orderDetails?.billing?.state}
                </h5>
                <h5>
                  {orderDetails?.shipping?.phone ||
                    orderDetails?.billing?.phone}
                  ,
                  {orderDetails?.shipping?.postcode ||
                    orderDetails?.billing?.postcode}
                </h5>
              </span>
            </label>
          </div>

          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Tax Deduction:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700 px-[3px] md:p-0">
                {orderDetails?.currency_symbol || "--"}{" "}
                {orderDetails?.total_tax || "--"}
              </span>
            </label>
          </div>

          {orderDetails?.payment_method ? (
            <div className="cursor-pointer mb-2">
              <label
                htmlFor={"1"}
                className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
              >
                <div>
                  <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                    Payment Method:
                  </span>
                </div>
                <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700 px-[3px] md:p-0">
                  {orderDetails?.payment_method || "--"}
                </span>
              </label>
            </div>
          ) : (
            <></>
          )}
          {/* {orderDetails.coupon_lines.length === 0 ?  "CUPPON NO":"CUPPON HAS"} */}
          {(coupons && coupons?.length > 0 && orderDetails.coupon_lines.length !== 0) &&
            coupons.map((coupon: any) => {
              return (
                <div className="cursor-pointer mb-2">
                  <label
                    htmlFor={"1"}
                    className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
                  >
                    <div>
                      <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                        Discount{" "}
                        {coupon?.code ? (
                          <span className="relative px-1 pr-[20px] py-1 ml-2 border border-y border-solid rounded-[6px] text-[14px] border-primary bg-[rgba(252,122,120,0.1)]">
                            {coupon.code?.toUpperCase()}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700 px-[3px] md:p-0">
                      {coupon?.amount ? `-$${coupon?.amount}` : "-$0.00"}
                    </span>
                  </label>
                </div>
              );
          })}
          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Total:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular font-regular text-gray-700 px-[3px] md:p-0">
                {orderDetails?.currency_symbol || "--"}

                { orderDetails.coupon_lines.length !== 0 ? (Number(orderDetails?.total) +  Number(orderDetails?.total_tax) - Number(appliedCouponPrice)).toFixed(2): (Number(orderDetails?.total) +  Number(orderDetails?.total_tax)).toFixed(2)}
              </span>
            </label>
          </div>

          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Order status:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular text-gray-700 capitalize">
                {orderDetails?.status || "--"}
              </span>
            </label>
          </div>
          <div className="cursor-pointer mb-2">
            <label
              htmlFor={"1"}
              className="flex justify-between cursor-pointer items-baseline md:items-center !mb-0"
            >
              <div>
                <span className="text-[15px] font-sofiaSemibold font-semiBold text-gray-700">
                  Tracking ID:
                </span>
              </div>
              <span className="ml-1 text-[15px] font-sofiaRegular text-gray-700 capitalize">
                {notes}
              </span>
            </label>
          </div>
        </div>

        <div className="flex justify-end px-2 my-4">
          <button
            onClick={() => {
              onPressBack();
            }}
            className="bg-black text-white whitespace-nowrap md:px-4 px-1 py-2 rounded-[21px] font-sofiaRegular font-regular md:text-[16px] text-[14px] hover:bg-primary transition-colors duration-300 w-[100px]"
          >
            Back
          </button>
          {!["completed", "cancelled", "failed", "refunded"].includes(
            orderDetails?.status
          ) ? (
            <button
              onClick={() => {
                onPressCancelOrder(orderDetails);
              }}
              className="btn mx-1 py-2 px-4 font-sofiaRegular font-regular focus:outline-none rounded-[21px] bg-bg-grey hover:text-white hover:bg-black"
            >
              Cancel order
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewOrderDetails;
